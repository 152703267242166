@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap);

.body,
h3,
table {
    font-family: var(--font-family-nunito) !important;
}

.fixed .main-header {
    box-shadow: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.box {
    background: none;
}

.sp-cnt .modal-content {
    width: auto;
}

.sp-cnt .modal {
    display: block;
}

.sp-cnt .listbox {
    padding-top: 15px;
    padding-bottom: 9px;
}

.sp-cnt .sel-week-days {
    border-radius: 50%;
    margin: 0px 2px 0px 2px;
    outline: none !important;
}

.sp-cnt .sel-week-days span {
    inline-size: 10px;
    display: inline-block;
    line-height: 20px;
    width: 10px;
}

.sp-cnt .sel-day-opt {
    width: 50%;
    display: inline-block;
}

.sp-cnt .modal-sm {
    min-width: 366px;
}

.sp-cnt .option-bar>* {
    display: inline-block !important;
    margin: 0px 10px 0px 10px;
}

.sp-cnt .fc-day-grid-container {
    height: 340px !important;
}

.sp-cnt .fc-widget-content {
    height: 50px;
}

.sp-cnt .selection {
    background: rgba(46, 115, 252, 0.11);
    border-radius: 0.1em;
    border: 2px solid rgba(98, 155, 255, 0.81);
}

.sp-cnt .DateRangePicker_picker {
    z-index: 50;
}

.sp-cnt .navbar {
    margin-bottom: 0px;
}

.sp-cnt .nav>li>span {
    position: relative;
    display: block;
    padding: 5px 15px;
    font-weight: 500;
}

.sp-cnt .navbar-default .navbar-nav>.disabled>span,
.sp-cnt .navbar-default .navbar-nav>.disabled>a,
.sp-cnt .navbar-default .navbar-nav>.disabled>a:focus,
.sp-cnt .navbar-default .navbar-nav>.disabled>a:hover {
    color: #ccc;
    background-color: transparent;
    cursor: not-allowed;
    pointer-events: none;
}

.sp-cnt .navbar-default .navbar-nav>li>span {
    color: #777;
    font-size: 0.9vw;
}

.sp-cnt .nav>li>span:hover,
.sp-cnt .nav>li>span:active,
.sp-cnt .nav>li>span:focus {
    color: #777;
    ;
    background: #f7f7f7;
    cursor: pointer;
}

.sp-cnt .nav>li>span:hover,
.sp-cnt .nav>li.focus>span,
.sp-cnt .nav>li>span:focus {
    color: var(--primary-green);
    background: #f7f7f7;
    cursor: pointer;
    font-weight: 700;
    transition: all 0.1s ease-in-out;
    font-size: 0.9vw;
}

.sp-cnt .nav>li.activeli:not(.disabled)>span {
    color: #57af6c;
    background: #f7f7f7;
    cursor: pointer;
}

.sp-cnt .navbar-default .navbar-nav>.active>a,
.sp-cnt .navbar-default .navbar-nav>.active>a:focus,
.sp-cnt .navbar-default .navbar-nav>.active>a:hover {
    color: #555;
    background-color: unset;
}

.sp-cnt .navbar-default .navbar-nav>.beautiful-li:not(.disabled) {
    --border-color: #57af6c;
    --border-width: 3px;
    --bottom-distance: 0px;
    color: #666;
    display: inline-block;
    background-image: linear-gradient(var(--border-color), var(--border-color));
    background-size: 0% var(--border-width);
    background-repeat: no-repeat;
    transition: background-size 0.3s;
}

.sp-cnt .navbar-default .navbar-nav>li {
    margin: 3px 0;
}

.sp-cnt .navbar-default .navbar-nav>.beautiful-li:hover {
    background-size: 100% var(--border-width);
}


.sp-cnt .navbar-default .navbar-nav>.beautiful-li.active {
    background-size: 100% var(--border-width);
}

.sp-cnt .fromCenter {
    background-position: 50% calc(100% - var(--bottom-distance));
}

.sp-cnt .nav>li>span::after {
    display: block;
    content: attr(title);
    font-weight: 700;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
}

@media (min-width: 768px) {

    .sp-cnt .navbar-nav>li>span {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.sp-cnt .CalendarDay__selected_span {
    background: #82e0aa;
    color: white;
    border: 1px solid lightcoral;
}

.sp-cnt .CalendarDay__selected {
    background: darkred;
    color: white;
}

.sp-cnt .CalendarDay__selected:hover {
    background: orange;
    color: white;
}

.sp-cnt .CalendarDay__hovered_span:hover,
.sp-cnt .CalendarDay__hovered_span {
    background: brown;
}

/* .sp-cnt .fc-event {
  line-height: 0.4 !important;
  border-radius: 0px;
} */

.sp-cnt .fc-title {
    visibility: hidden;
}

.sp-cnt .fc-day-grid-event {
    margin: 0px 2px 0;
    padding: 0 1px;
}

.sp-cnt .navbar-nav>li>a {
    line-height: 10px;
}

.sp-cnt .nav {
    padding-right: 0px;
}

.sp-cnt .well-dark {
    background-color: #707070;
    color: #FFFFFF;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: space-between;
}

.sp-cnt .rounded-button {
    border: 1px solid #FFF;
    color: #FFF;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding-left: 10px;
    padding-right: 10px;
}

.sp-cnt .rounded-button:hover {
    color: #d0d0d0;
}

.stats-table>tbody>tr>td {
    padding: 15px;
    vertical-align: middle;
}

.stats-table {
    font-family: var(--font-family-nunito);
    font-size: var(--size-14);
    color: var(--black);
}

.stats-table>thead {
    background-color: var(--white);
    border-bottom: 3px solid var(--primary-green);
    color: var(--indigo);
    font-weight: bold;
}

.stats-table>thead>tr>th {
    padding: 15px;
}

.stats-table>tbody>tr:nth-child(even)>td,
.stats-table>tbody>tr:nth-child(even)>th {
    background-color: white;
}

.stats-table>tbody>tr:nth-child(odd)>td,
.stats-table>tbody>tr:nth-child(odd)>th {
    background-color: #faf8f8ef;
}

.stats-table {
    font-family: var(--font-family-nunito);
    font-size: var(--font-size-14);
}

/* .table-title {
  margin-top: 50px;
  margin-bottom: 5px;
}

.sp-cnt .stats-table>thead>tr>td {
  background-color: #f8f8f8;
}

.sp-cnt .table-bordered, .sp-cnt .table-bordered>thead>tr>th, .sp-cnt .table-bordered>tbody>tr>th, .sp-cnt .table-bordered>tfoot>tr>th, .sp-cnt .table-bordered>thead>tr>td, .sp-cnt .table-bordered>tbody>tr>td, .sp-cnt .table-bordered>tfoot>tr>td {
  border: 1px solid #D5D5D5;
}

.table-condensed>tbody>tr:nth-child(even)>td {
  background-color: #f7f7f7 !important;
} */

.sp-cnt .para-title {
    color: #1F7B9E;
    font-weight: 500;
    font-size: 20px;
}

.sp-cnt .space-pref-search>input {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    border-left: none;
    padding: 6px 6px;
    background-color: var(--white);
}

.sp-cnt .space-pref-search .input-group-addon {
    padding: 6px 8px;
    background-color: var(--white);
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    border-right: none;
}

.location-date-divider {
    display: inline-block;
    vertical-align: top;
    border-left: 1px solid rgba(0, 0, 0, 0.12);
    width: 2px;
    height: 40%;
    margin: 15px 10px 0px 0px;
}

.calendar-icon-wrapper {
    display: inline-block;
    vertical-align: top;
    -ms-transform: scale(0.5);
    transform: scale(0.8);
    line-height: 50px;
    margin-top: 1px;
    /* margin: 1.1% 0px 0px 0px; */
}

.calendar-date-icon-wrapper {
    display: inline-block;
    vertical-align: top;
    -ms-transform: scale(0.5);
    transform: scale(0.8);
    margin: 11px 0px 0px 0px;
}

.sp-dropdown-icon-wrapper {
    display: inline-block;
    vertical-align: top;
    margin: 14px 0px 0px 0px;
    cursor: pointer;
}

.sp-capcity-subHeader-container {
    width: 100%;
    height: 65px;
    display: flex;
    flex-flow: row;
}

.sp-capcity-view-section-overlayer {
    position: absolute;
    z-index: 15;
    background: rgba(0, 0, 0, 0.3);
    height: 82%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sp-capcity-view-section-overlayer-message-card {
    height: 160px;
    width: 320px;
    background-color: #FFFFFF;
    font-family: var(--font-family-nunito);
    border-radius: 8px;
    box-shadow: 0px 0px 20px rgb(0 0 0 / 25%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    text-align: center;
    padding: 10px;
    font-size: 12px;
}

.sp-capcity-view-section-overlayer-message-card>div {
    font-size: 14px;
    margin: 5px 0px;
}

.sp-capcity-view-section {
    display: flex;
    flex: 1 1 auto;
    flex-flow: row;
    border-top: 2px solid var(--primary-green);
}

.sp-capacity-display-container {
    display: flex;
    flex-flow: column;
    height: 91%;
}

.sp-demand-department-container {
    width: 35% !important;
}

.sp-capacity-department-container {
    width: 30%;
    height: 100%;
    border-right: 1.5px solid rgba(0, 0, 0, 0.12);
    padding: 15px;
    color: rgba(68, 68, 68, 0.8);
    font-family: var(--font-family-nunito);
    font-style: normal;
}

.sp-capacity-department-container-data {
    display: flex;
    flex-flow: column;
    padding: 0px !important;
}

.sp-switch-button-container {
    align-items: center;
    display: flex;
    justify-content: center;
}

.sp-switch-button-style {
    /* transform: scale(0.7); */
    /* height: auto; */
    margin: 5px;
    border-radius: 20px;
    height: 16px;
    width: 28px;
    border: 2px solid #919191;
    max-width: none;
    cursor: pointer;
}

.sp-floor-switch-button-style {
    transform: scale(0.9);
    float: right;
    margin: 0px 0px 0px 8px;
    cursor: pointer;
}

.sp-capacity-display-container .slide:before {
    position: absolute;
    content: "";
    bottom: -2px;
    left: -2px;
    height: 16px;
    width: 16px;
    /* border: 2px solid #919191; */
    -webkit-transition: .4s;
    transition: .4s;
}

.sp-capacity-display-container input:focus+.slide {
    box-shadow: 0 0 1px #2196F3;
}

.sp-capacity-display-container input:checked+.slide:before {
    background-color: #38D68B;
    border: 2px solid #38D68B;

    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
    /* background-color: blue; */
}

.sp-capacity-display-container .slide.rounded {
    border-radius: 34px;
}

.sp-capacity-display-container .slide.rounded:before {
    border-radius: 50%;
}

.sp-capacity-display-container .slide.rounded:before {
    border-radius: 50%;
}

.sp-capacity-department-selected {
    background-color: rgba(56, 214, 139, 0.2);
}

.sp-capacity-department-list {
    overflow-y: auto;
    display: flex;
    flex: 1 1 auto;
    flex-flow: column;
    height: 200px;
}

.sp-capacity-department-card {
    padding: 14px 10px;
    display: flex;
    flex-flow: row;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.sp-capacity-department-card-legend {
    /* width: 40px;
  height: 40px;
  min-width: 40px; */
    width: 2.6vw;
    height: 2.6vw;
    min-width: 2.6vw;
    border-radius: 50px;
    background-color: lightgray;
    margin: 10px;
}

.sp-capacity-department-card-label {
    font-family: var(--font-family-nunito);
    font-style: normal;
    color: #444444;
    /* font-size: 16px; */
    font-size: 0.9vw;
    font-weight: 600;
    padding: 0px 0px 2px 4px;
}

.sp-capacity-department-button-container {
    height: 50px;
    background-color: #E7E7E7;
}

.sp-capacity-department-card-label-container {
    display: flex;
    flex-flow: row;
    flex: 1 1 auto;
    /* font-size: 12px; */
    font-size: 0.80vw;
    font-weight: 600;
    color: rgba(68, 68, 68, 0.6);
}

.sp-capacity-department-select-container {
    width: 140px;
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
}

.sp-capacity-department-select-option-container {
    padding: 5px;
    font-size: 0.75vw;
    font-family: var(--font-family-nunito);
    font-style: normal;
    font-weight: 400;
    /* display: flex; */
    /* min-width: 200px; */
    align-items: center;
    cursor: pointer;
    width: auto;
    white-space: nowrap;
}

.sp-capacity-department-label-container {
    display: inline-block;
    vertical-align: top;
    /* font-size: 14px; */
    font-size: 0.85vw;
    color: #2E375B;
    font-weight: 700;
    margin: 5px 0px 0px 5px;
}

.sp-visitor-count-label-container {
    display: inline-block;
    vertical-align: top;
    font-size: 0.70vw;
    font-weight: 600;
    color: var(--white);
    margin: 8px 0px 0px 5px;
    width: auto;
    padding: 0px 6px;
    background-color: var(--secondary-orange);
    border-radius: 6px;
    min-width: 20px;
    text-align: center;
}

.sp-visitor-clear-allocation {
    float: right;
    margin-right: 10px;
    vertical-align: middle;
    height: 100%;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
}

.sp-visitor-download-text {
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
}

.sp-visitor-seat-avialable-count {
    background-color: var(--hover-green);
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
    min-width: 20px;
    text-align: center;
    font-weight: 600;
    font-size: var(--size-vw-0-75);
    border-radius: 3px;
    color: rgba(68, 68, 68, 0.75);
}

.sp-capacity-department-label {
    font-size: 14px;
    color: #2E375B;
}

.sp-capacity-floormap-container {
    flex: 1;
    display: flex;
    padding: 15px;
    font-family: var(--font-family-nunito);
    font-style: normal;
}

.sp-capacity-floormap-data-container {
    vertical-align: top;
    display: inline-block;
    padding-top: 5px;
    margin: 0px 5px;
}

.sp-capacity-floormap-data-label-container {
    font-weight: 500;
    /* font-size: 13px; */
    font-size: 0.85vw;
    margin-right: 6px;
    margin-bottom: unset;
    color: var(--indigo);
}

.sp-capacity-floormap-data-value-container {
    font-weight: 700;
    font-size: 0.85vw;
    margin-bottom: unset;
    color: var(--indigo);
}

.sp-capacity-floormap-unavailable-legend {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    background-color: #BBBBBB;
    border-radius: 50px;
    font-size: 12px;
    line-height: 16px;
    margin-top: 2px;
}

.sp-react-select__placeholder {
    color: var(--black) !important;
    font-size: 12px !important;
}

.sp-react-select-container {
    font-family: var(--font-family-nunito) !important;
    color: var(--black) !important;
}

input:focus,
textarea:focus,
select:focus {
    outline: none;
}

.sp-react-select__control {
    border-radius: 25px !important;
    min-height: 28px !important;
    height: 28px !important;
    line-height: 28px !important;
    background-color: var(--white) !important;
    border: var(--primary-border) !important;
    margin: 0px !important;
    cursor: pointer !important;
    font-size: 0.85vw !important;
}

.sp-react-select__indicator-separator {
    width: 0px !important;
}

.sp-react-select__dropdown-indicator {
    /* color: var(--primary-green); */
    padding: 0px 6px 0px 0px !important;
}

.sp-react-select__menu {
    height: auto !important;
    box-shadow: var(--primary-box-shadow) !important;
    border: var(--dropdown-border) !important;
    border-radius: 6px !important;
    width: auto !important;
}

/* input[type=text]:focus {
  border: 1px solid var(--primary-green);
} */

/* .sp-react-select__single-value {
  opacity: .8;
  position: static;
  transform: none !important;
  top: 0px !important;
  font-size: 0.75vw !important;
} */

.sp-react-select__value-container {
    padding: 0px 8px !important;
    max-width: 110px !important;
    font-size: 0.85vw !important;
}

div.sp-react-select__value-container>* {
    padding: 0px !important;
    margin: 0px !important;
}

.sp-display-inline {
    display: inline-block;
    vertical-align: top;
}

.sp-react-select__menu-list {
    overflow-x: hidden !important;
}


.sp-capacity-map-location-search-select-container {
    width: 140px;
    display: inline-block;
    vertical-align: top;
    /* margin-left: 10px; */
}

.search-icon {
    /* background-image: url('/images/akar-icons_search.svg'); */
    margin: 0px 8px 0px 0px !important;
    order: 1;
}

.search-icon>path {
    display: none;
}

.sp-react-select__option {
    margin: 0px !important;
    background: transparent !important;
}

.selectionRow:hover {
    background-color: rgba(220, 245, 226, 0.6);
}