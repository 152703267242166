.custom-checkbox.checked {
  background-color: rebeccapurple;
  /* Change the color to your desired color */
}

/* Style for the disabled checkbox */
input[type="checkbox"][disabled] {
  cursor: not-allowed;
  position: relative;
  opacity: 0.6;
  border: 2px solid #E5E5E5;
}

/* Style for the checkbox */
input[type="checkbox"] {
  cursor: pointer;
  width: 20px;
  height: 20px;
  appearance: none;
  border: 2px solid #C3C3C3;
  /* Default border color */
  border-radius: 4px;
  position: relative;
}

/* Pseudo-element to create the checkmark */
input[type="checkbox"]::after {
  content: "\2713";
  /* Unicode character for '✓' */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  /* Hide the checkmark initially */
  font-size: 14px;
  color: white;
  /* Color for the checkmark */
  transition: transform 0.2s;
}

/* Style for checked checkbox */
input[type="checkbox"]:checked {
  background-color: #6ADA96;
  /* Change the background color to green when checked */
  border-color: #6ADA96;
  /* Change the border color to green when checked */
}

/* Show the checkmark when the checkbox is checked */
input[type="checkbox"]:checked::after {
  transform: translate(-50%, -50%) scale(1);
}