.uploadFile {
    padding-top: 10rem;
}

.uploadFile label {
    display: flex;
    flex-direction: column;
    border: 1px dashed rgb(204, 204, 204);
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    margin: 20px 0px;
    cursor: pointer;
    height: 200px;
    width: 500px;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 13px;
    color: rgb(68, 68, 68);
    line-height: 18px;
}

.uploadingBtn {
    z-index: 3;
    position: absolute;
    left: 10rem;
    /* transform: translateY(-57%); */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;

}

/* @media screen and (max-width: 768px) {
    .uploadFile {
        padding-top: 10rem;
        padding-left: 8.6rem;
    }

    .uploadingBtn {
        left: 19rem;
    }
} */