/* Add some basic styling to the search input box */
.searchBoxInputField {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative;
    display: inline-block; */
}

.searchBoxInputField input[type="text"] {
    padding: 8px 40px 8px 10px;
    border: 1px solid #748ADF;
    border-radius: 8px;
    font-size: 14px;
    width: 180px;
    height: 32px;
    top: 144px;
    left: 162px;
}

/* Position the search icon within the input box */
.searchBoxInputField .search-icon,
.cross-icon {
    position: absolute;
    right: 10px;
}